import React from "react";
import './index.less';
import Header from "../../components/header";
import MainImg from '../../assets/yanxuan/lianxiwomen/main.png';
import MainImg2 from '../../assets/yanxuan/lianxiwomen/main2.png';
import MainImg3 from '../../assets/yanxuan/lianxiwomen/main3.png';
import BottomView from "../../components/bottomView";
import ToTop from "../../components/toTop";
import 'antd/dist/antd.min.css';
// import AiAssistant from "../../components/aiAssistant";

const Contact = () => {
  return <div className="CONTACTUS">
    <Header index='contact' />
    <img src={MainImg} style={{ width: '100%' }} />
    <img src={MainImg2} style={{ width: '100%' }} />
    <img src={MainImg3} style={{ width: '100%' }} />
    {/* <AiAssistant/> */}
    <BottomView loca='contact' />
    <ToTop red />
  </div>
}

export default Contact;
