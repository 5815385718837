import React, { useState } from "react";
import './index.less';
import { Modal ,Button,Tooltip} from "antd";

const CertItem = (props) => {
  const { data, theUrl } = props;
  const [showBig, setShowBig] = useState(false);
  const handleCancel = ()=>{
    setShowBig(false)
  }
  return <div className="certItem">
    <div className="left">
      <img className="leftImg" src={`${theUrl}${data.images}`} onClick={()=>{setShowBig(true)}} />
    </div>
    <div className="right">
      <div className="certInfo">{data.name}-<span>{data.sex == 1 ? '男' : '女'}</span></div>
      <div className="certInfo">证书类型：{data.type == 4 ? '中医健康管理师' : ''}{data.type == 1 ? '中医药茶师' : ''}{data.type == 2 ? '中药炮制与配制工' : ''}{data.type == 3 ? '中药调剂员' : ''}{data.type == 5 ? '中医针灸师' : ''}</div>
      <div className="certInfo">科目课程：{data.course}</div>
      <div className="certInfo">等级：{data.level}级</div>
      <div className="certInfo">证书编号：{data.code}</div>
      <div className="certInfo">颁发机构：{data.agency}</div>
      <div className="certInfo">颁发日期：{data.build_at}</div>
      <div className='certInfo'>其它信息：<Tooltip placement="topLeft" title={data.desc}>
      {data.desc}
      </Tooltip></div>
    </div>
    <Modal open={showBig} footer={[
          <Button key="back" onClick={handleCancel}>
            关闭
          </Button>
        ]}  onOk={() => { setShowBig(false) }} onCancel={() => { setShowBig(false) }}>
      <img className="bigImg" src={`${theUrl}${data.images}`} />
    </Modal>
  </div>
}

export default CertItem;